import { BaseConfig } from '@danone-global/internal/react/core'
import { messages } from '@danone-global/internal/react/modules/checkout'
import React from 'react'

export const baseEnvironment: BaseConfig = {
  storeName: 'Aptamil',

  siteName: 'ch-am',

  siteSlug: 'ch-am',

  defaultLocale: 'de-CH',

  providerId: 'danis_eu',

  ctHost: 'https://api.europe-west1.gcp.commercetools.com',

  ctAuthHost: 'https://auth.europe-west1.gcp.commercetools.com',

  ctStoreKey: 'APTA',

  localeConfigs: [
    {
      locale: 'de-CH',
      country: 'CH',
      currency: 'CHF',
      initMessages: () =>
        import('../translations/de_CH.json').then((r) => r.default),
      urls: {
        faq: 'https://www.aptaclub.ch/aptamil-produkte/faq.html',
        privacy: 'https://www.aptaclub.ch/datenschutzerklaerung.html',
        dataCollection: 'https://www.aptaclub.ch/datenschutzerklaerung.html',
        termsAndConditions: 'https://www.aptaclub.ch/nutzungsbedingungen.html',
        contact: 'https://www.aptaclub.ch/mein-aptaclub/kontakt.html',
      },
    },
    {
      locale: 'fr-CH',
      country: 'CH',
      currency: 'CHF',
      initMessages: () =>
        import('../translations/fr_CH.json').then((r) => r.default),
      urls: {
        faq: 'https://www.aptaclub.ch/fr/produits-aptamil/faq.html',
        privacy: 'https://www.aptaclub.ch/fr/politique-de-confidentialite.html',
        dataCollection:
          'https://www.aptaclub.ch/fr/politique-de-confidentialite.html',
        termsAndConditions:
          'https://www.aptaclub.ch/fr/termes-et-conditions-generales.html',
        contact: 'https://www.aptaclub.ch/fr/mon-aptaclub/contact.html',
      },
    },
  ],

  analytics: {
    tracker: null,
    language: 'de-CH',
    defaultCategory: 'Baby food',
  },

  overrides: {
    'shopping-cart-v2': {
      variant: 'badge',
    },
  },

  passwordValidation: {
    mustHaveLowercaseAndUppercase: true,
    specialCharacters: 'DACH',
  },

  checkout: {
    stepAddress: React.lazy(
      () =>
        // imports should be on one line, in order to trigger transifex extract actions!
        // prettier-ignore
        import('@danone-global/internal/react/components/checkout/ch-step-address'),
    ),
    payment: React.lazy(
      () =>
        // prettier-ignore
        import('@danone-global/internal/react/components/payment-providers/adyen-drop-in'),
    ),

    checkboxes: [
      {
        name: 'isMarketingOptedIn',
        required: false,
        store: true,
        label: messages.isMarketingOptedIn,
      },
      {
        name: 'agreeTerms',
        required: true,
        store: false,
        label: messages.agreeTerms,
      },
      {
        name: 'agreeDataSecurity',
        required: true,
        store: false,
        label: messages.agreeDataSecurity,
      },
    ],
  },
}
