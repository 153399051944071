import { createTheme, Theme } from '@danone-global/internal/react/core'

const theme: Theme = createTheme({
  direction: 'ltr',

  unit: 'px',

  typography: {
    fontFamily: 'Montserrat regular, sans-serif',
    fonts: [],
  },

  palette: {
    text: {
      primary: '#4c4c4c',
      secondary: '#fff',
      disabled: '#8C8C8C',
      heading: '#4c4c4c',
    },

    primary: {
      light: '#d7efff',
      main: '#001489',
      dark: '#00a9e0',
    },

    secondary: {
      light: '#feefe5',
      main: '#FE6600',
      dark: '',
    },

    background: {
      secondary: '#d7efff',
    },
    success: {
      light: '#E8F2E6',
      main: '#078900',
      dark: '',
    },

    warning: {
      light: '#F8EAEC',
      main: '#E03247',
      dark: '',
    },
  },

  breakpoints: {
    values: {
      md: 816,
      lg: 976,
    },
  },

  shape: {
    borderRadius: 12,
  },
})

theme.overrides = {
  Typography: {
    h1: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      fontSize: 30,
      lineHeight: '36px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 40,
        lineHeight: '46px',
      },
    },

    h2: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      fontSize: 24,
      lineHeight: '30px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
        lineHeight: '37px',
      },
    },

    h3: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 20,
      lineHeight: '25px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '28px',
      },
    },

    h4: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 18,
      lineHeight: '23px',

      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },

    h5: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 16,
      lineHeight: '20px',
    },
  },

  LineItem: {
    productName: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },

    removeContainer: {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },

    viewItemLink: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      fontSize: 15,
    },

    label: {
      backgroundColor: theme.palette.primary.main,

      '& p': {
        color: theme.palette.common.white,
      },
    },

    labelInscription: {
      backgroundColor: theme.palette.primary.dark,
    },

    checkout: {
      '& $subContainer p': {
        color: theme.palette.text.primary,
        opacity: 0.8,
      },
    },
    quantityContainer: {
      '& p': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    amountContainer: {
      '& p': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },

  LineItemInscriptions: {
    inscriptionInputsLabel: {
      '& label span': {
        fontWeight: 600,
      },
    },
    inscriptionsSummary: {
      background: theme.palette.primary.main,

      '&::before': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },

      [theme.breakpoints.up('md')]: {
        background: theme.palette.primary.main,

        '&::before': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        },

        '& p$inscriptionsSummaryItem': {
          color: 'white',
        },
      },
    },
    inscriptionsSummaryTitle: {
      [theme.breakpoints.up('md')]: {
        color: 'white',
      },
    },
  },

  FieldsStepper: {
    label: {
      color: theme.palette.primary.main,
    },

    control: {
      backgroundColor: theme.palette.common.white,
      fill: theme.palette.primary.main,
      border: theme.utils.createStyle(
        theme.palette.primary.main,
        '2px',
        'solid',
      ),

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  RelatedProducts: {
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
    },

    default: {
      '& $hr': {
        borderTopColor: 'rgba(151, 151, 152, 0.25)',
      },
    },
  },

  RelatedProduct: {
    container: {
      '& button': {
        padding: theme.spacing(1, 2),
      },
    },
  },

  Button: {
    default: {
      borderColor: theme.palette.primary.main,

      '&:hover': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },

    reversed: {
      //
    },

    link: {
      '&:disabled, &:disabled:hover': {
        color: `theme.palette.text.primary !important`,
        opacity: 0.5,
      },
    },
  },

  AddDiscountCode: {
    addButton: {
      backgroundColor: 'transparent',
    },
  },

  DiscountCodePrice: {
    unmatchedDiscountText: {
      color: '#E03247',
    },
  },

  Price: {
    root: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    additionalPrice: {},
  },

  Container: {
    root: {
      border: '1px solid rgba(151, 151, 152, 0.25)',
    },
  },

  Notification: {
    root: {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
    },

    indicator: {
      '&::before': {
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.main,
      },
    },
  },

  ShoppingCart: {
    button: {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },

    badge: {
      '& $itemCount': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },

  SubscriptionSelector: {
    optionContainer: {
      '& p': {
        color: theme.palette.primary.main,
      },
    },
  },

  Checkout: {
    securePayment: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  CheckoutViewProducts: {
    titleContainer: {
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },

  StepContainer: {
    container: {
      '& h4': {
        color: theme.palette.primary.main,
      },
    },
  },

  FieldsCheckbox: {
    label: {
      lineHeight: '24px',

      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  },

  FieldsRadio: {
    option: {
      '& label': {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.main,
      },
    },
  },

  FieldsInput: {
    input: {
      borderRadius: 4,
      height: 36,
    },
  },

  FieldsAutoComplete: {
    input: {
      borderRadius: 4,
    },
  },

  FieldsSelect: {
    root: {
      '& label': {
        lineHeight: '20px',
      },
    },
    input: {
      backgroundColor: '#E4EDF7',
      color: theme.palette.primary.main,
      borderRadius: 6,
      border: 'none',
      height: 36,
    },
    inputWrap: {
      backgroundColor: '#E4EDF7',
      borderRadius: 6,
      paddingRight: 6,
    },
  },

  MyOrder: {
    root: {
      '& p': {
        color: theme.palette.primary.main,
      },
    },

    value: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
  },

  CheckoutOrder: {
    value: {
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    totalPrice: {
      color: theme.palette.primary.main,
    },
  },

  AdyenDropIn: {
    root: {
      ['& .adyen-checkout__payment-method, label > span, ' +
      '.adyen-checkout__payment-method__header, ' +
      '.adyen-checkout__payment-method__name--selected, ']: {
        color: theme.utils.createStyle(
          theme.palette.primary.main,
          '!important',
        ),
        fontSize: 16,
      },

      '& .adyen-checkout__payment-method__name, .adyen-checkout__payment-method label':
        {
          color: theme.utils.createStyle(
            theme.palette.primary.main,
            '!important',
          ),
          fontWeight: theme.typography.fontWeightMedium,
        },

      '& label > span': {
        color: theme.palette.primary.main,
        lineHeight: '1em',
      },
    },
  },

  OrderHistoryList: {
    order: {
      border: '1px solid #DDDDDD',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.04)',
    },

    orderTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },

    lineTitle: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  OrderHistoryDetail: {
    root: {
      border: '1px solid #DDDDDD',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.04)',
    },
  },
  AddToCart: {
    root: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'start',
      },
    },
  },

  NotifyMeModal: {
    notification: {
      background: '#E5E7F2',
    },
  },

  WidgetReviews: {
    sorting: {
      '& .reviews-sorting': {
        '&__control': {
          borderRadius: '6px',
          backgroundColor: '#E4EDF7',
          border: 'none',
        },

        '&__placeholder, &__single-value': {
          color: theme.palette.primary.main,
        },

        '&__indicator > svg[width]': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },

  Review: {
    action: {
      backgroundColor: '#E4EDF7',
      color: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: '#E4EDF7',
        color: theme.palette.primary.main,
      },
    },
    careLineContent: {
      backgroundColor: '#E4EDF7',
    },
  },

  CreateReview: {
    productWrap: {
      backgroundColor: '#E4EDF7',
    },
  },

  CreateUserStep: {
    codeActions: {
      '& button': {
        color: theme.palette.primary.dark,
      },
    },
  },
}

export { theme }
