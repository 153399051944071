import { Config } from '@danone-global/internal/react/core'

import { baseEnvironment } from './environment.base'

export const environment: Config = {
  ...baseEnvironment,

  isProduction: true,

  isDevelopment: false,

  ctProjectKey: 'ch-prd-danone',

  ctClientId: 'qxjDwiYUxJvZLeo1jkgE09wZ',

  ctClientSecret: 'ze7Ex00tWiHO94xlrLmi3yZ0D9pXb7Qn',

  defaultChannelId: '8352a52e-1398-40bc-b328-8119675d9470',

  sentryDSN:
    'https://26e95ebef18de217c29264c5f256280b@o4506427613577216.ingest.sentry.io/4506433323008000',

  adyen: {
    clientKey: 'live_FYZX6QB7RJDYTFHXO4UAMONCQIOTBMFS',
    environment: 'live',

    googlePay: {
      environment: 'PRODUCTION',
      gatewayMerchantId: 'DanoneNutricia',
      merchantName: 'Nutricia Milupa GmbH',
      merchantId: 'BCR2DN6TT6SZHQQD',
    },
  },
}
